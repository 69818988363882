import React, { useState, useEffect } from "react";
import { makeStyles, Box, Typography, Paper, Button } from "@material-ui/core";
import { IoBasketballSharp } from "react-icons/io5";
import { SiBinance } from "react-icons/si";
import { BsCheck2 } from "react-icons/bs";
import dateFormat from "dateformat";
import { claimBet } from "src/services/MoneyLineBets";
import SnackbarService from "src/services/SnackbarService";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useMoralis, useNewMoralisObject } from "react-moralis";
import { getSingleGame, getOddAPI } from "src/services/ApiCall";

const useStyles = makeStyles((theme) => ({
  headerBetWin: {
    background: theme.palette.background.heading,
    padding: "8px 12px",
    "& p.win": {
      color: "#E45A5A",
    },
  },
  secondMainBox: { padding: "10px" },
  subtitle: {
    paddingTop: "5px",
    "& svg": {
      color: "#39AED0",
      fontSize: "16px",
      marginRight: "7px",
    },
    "& h6": {
      fontSize: "14px",
      lineHeight: "1.2",
      color: theme.palette.text.noticetext,
    },
  },
  mainBox: {},
  lowerbetbox: {
    "& p": {
      fontSize: "12px",
      marginBottom: "4px",

      "&.blue": { color: "#39AED0" },
    },
    "& h6": {
      fontSize: "14px",
    },
    "& svg": {
      color: "orange",
      fontSize: "14px",
      marginLeft: "10px",
    },
  },
  loweroponent: {
    borderTop: "1px dashed rgba(102, 162, 211, 0.3);",
    paddingTop: "7px",
  },
  footerBetWin: {
    background: "#39AED0",
    padding: "8px 12px",
    cursor: "pointer",
    "& p.win": {
      color: "#E45A5A",
    },
  },
  cashoutButton: {
    padding: "unset !important",
    margin: "unset",
    height: "auto",
    background: "transparent",
    border: "unset",
    outline: "unset",
    boxShadow: "unset",

    "&:hover": {
      padding: "unset !important",
      margin: "unset",
      height: "auto",
      background: "transparent",
      border: "unset",
      outline: "unset",
      boxShadow: "unset",
    },
  },
}));

var array = [];
function ActiveBetsCard(props) {
  const classes = useStyles();
  const { Moralis } = useMoralis();
  const [status, setStatus] = useState(false);

  const [loader, setLoader] = useState(false);
  const [snackBarContent, setSnackBarContent] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarStatus, setSnackBarStatus] = useState("");
  const [matchDetails, setMatchDetails] = useState([]);
  const { save } = useNewMoralisObject("SettleBets");
  const { data, getActiveBets, getSettleBets } = props;
  const [gameStatus, setGameStatus] = useState("");
  const [isgame, setIsgame] = useState(false);
  useEffect(() => {
    console.log("data.attributes?.gameId : " + data.attributes?.gameId);
    console.log("matchD : ", getSingleMatcheDetails(data.attributes?.gameId));
  }, []);

  const snackBar = (msg, status) => {
    setSnackBarMsg(msg);
    setSnackBarStatus(status);
    setSnackBarContent(true);
    setTimeout(() => {
      setSnackBarContent(false);
    }, 2000);
  };

  const deleteData = async (obj) => {
    const query = new Moralis.Query("Bets");
    query.equalTo("objectId", obj);
    const object = await query.first();
    if (object) {
      object.destroy({ useMasterKey: true }).then(
        () => {
          console.log("Deleted Successfully!");
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const getWinLoss = (status, _array, type, betType) => {
    console.log("Status, type, _array : ", status, type, _array);
    console.log("betType : ", betType)
    if (status == true) {
      if (betType.includes("(Odd)") == true) {
        if (type == "1Qtr") {
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if (Number(_array.scores.home.quarter_1) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if (Number(_array.scores.away.quarter_1) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else {
            if ((Number(_array.scores.away.quarter_1) + Number(_array.scores.home.quarter_1)) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "2Qtr") {
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if (Number(_array.scores.home.quarter_2) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if (Number(_array.scores.away.quarter_2) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else {
            if ((Number(_array.scores.away.quarter_2) + Number(_array.scores.home.quarter_2)) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "3Qtr") {
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if (Number(_array.scores.home.quarter_3) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if (Number(_array.scores.away.quarter_3) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else {
            if ((Number(_array.scores.away.quarter_3) + Number(_array.scores.home.quarter_3)) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "4Qtr") {
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if (Number(_array.scores.home.quarter_4) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if (Number(_array.scores.away.quarter_4) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else {
            if ((Number(_array.scores.away.quarter_4) + Number(_array.scores.home.quarter_4)) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "1Half") {
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if ((Number(_array.scores.home.quarter_1) + Number(_array.scores.home.quarter_2)) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if ((Number(_array.scores.away.quarter_1) + Number(_array.scores.away.quarter_2)) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else {
            if ((Number(_array.scores.away.quarter_1) + Number(_array.scores.away.quarter_2) + (Number(_array.scores.home.quarter_1) + Number(_array.scores.home.quarter_2)) % 2 != 0)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "2Half") {
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if ((Number(_array.scores.home.quarter_3) + Number(_array.scores.home.quarter_4)) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if ((Number(_array.scores.away.quarter_3) + Number(_array.scores.away.quarter_4)) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else {
            if ((Number(_array.scores.away.quarter_3) + Number(_array.scores.away.quarter_4) + (Number(_array.scores.home.quarter_3) + Number(_array.scores.home.quarter_4)) % 2 != 0)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "GameFini") {
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if (Number(_array.scores.home.total) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if (Number(_array.scores.away.total) % 2 != 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else {
            if ((Number(_array.scores.away.total) + (Number(_array.scores.home.total)) % 2 != 0)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        }
      } else if (betType.includes("(Even)") == true) {
        if (type == "1Qtr") {
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if (Number(_array.scores.home.quarter_1) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if (Number(_array.scores.away.quarter_1) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else {
            if ((Number(_array.scores.away.quarter_1) + Number(_array.scores.home.quarter_1)) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "2Qtr") {
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if (Number(_array.scores.home.quarter_2) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if (Number(_array.scores.away.quarter_2) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else {
            if ((Number(_array.scores.away.quarter_2) + Number(_array.scores.home.quarter_2)) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "3Qtr") {
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if (Number(_array.scores.home.quarter_3) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if (Number(_array.scores.away.quarter_3) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else {
            if ((Number(_array.scores.away.quarter_3) + Number(_array.scores.home.quarter_3)) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "4Qtr") {
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if (Number(_array.scores.home.quarter_4) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if (Number(_array.scores.away.quarter_4) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else {
            if ((Number(_array.scores.away.quarter_4) + Number(_array.scores.home.quarter_4)) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "1Half") {
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if ((Number(_array.scores.home.quarter_1) + Number(_array.scores.home.quarter_2)) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if ((Number(_array.scores.away.quarter_1) + Number(_array.scores.away.quarter_2)) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else {
            if ((Number(_array.scores.away.quarter_1) + Number(_array.scores.away.quarter_2) + (Number(_array.scores.home.quarter_1) + Number(_array.scores.home.quarter_2)) % 2 == 0)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "2Half") {
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if ((Number(_array.scores.home.quarter_3) + Number(_array.scores.home.quarter_4)) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if ((Number(_array.scores.away.quarter_3) + Number(_array.scores.away.quarter_4)) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else {
            if ((Number(_array.scores.away.quarter_3) + Number(_array.scores.away.quarter_4) + (Number(_array.scores.home.quarter_3) + Number(_array.scores.home.quarter_4)) % 2 == 0)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "GameFini") {
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if (Number(_array.scores.home.total) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if (Number(_array.scores.away.total) % 2 == 0) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else {
            if ((Number(_array.scores.away.total) + (Number(_array.scores.home.total)) % 2 == 0)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        }
      } else if (betType.includes("(Over") == true) {
        let index = betType.lastIndexOf("(Over");
        let points = betType.slice(Number(index) + 6, Number(betType.length) - 1)
        if (type == "1Qtr") {
          if (Number(points) < (Number(_array.scores.home.quarter_1) + Number(_array.scores.away.quarter_1))) {
            setGameStatus("Win");
            setIsgame(true);
          } else {
            setGameStatus("Loss");
            setIsgame(false);
            claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
          }
        }else if (type == "2Qtr") {
          if (Number(points) < (Number(_array.scores.home.quarter_2) + Number(_array.scores.away.quarter_2))) {
            setGameStatus("Win");
            setIsgame(true);
          } else {
            setGameStatus("Loss");
            setIsgame(false);
            claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
          }
        }else if (type == "3Qtr") {
          if (Number(points) < (Number(_array.scores.home.quarter_3) + Number(_array.scores.away.quarter_3))) {
            setGameStatus("Win");
            setIsgame(true);
          } else {
            setGameStatus("Loss");
            setIsgame(false);
            claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
          }
        }else if (type == "4Qtr") {
          if (Number(points) < (Number(_array.scores.home.quarter_4) + Number(_array.scores.away.quarter_4))) {
            setGameStatus("Win");
            setIsgame(true);
          } else {
            setGameStatus("Loss");
            setIsgame(false);
            claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
          }
        }else if (type == "1Half") {
          if (Number(points) < (Number(_array.scores.home.quarter_1) + Number(_array.scores.away.quarter_1) + Number(_array.scores.home.quarter_2) + Number(_array.scores.away.quarter_2))) {
            setGameStatus("Win");
            setIsgame(true);
          } else {
            setGameStatus("Loss");
            setIsgame(false);
            claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
          }
        }else if (type == "2Half") {
          if (Number(points) < (Number(_array.scores.home.quarter_3) + Number(_array.scores.away.quarter_3) + Number(_array.scores.home.quarter_4) + Number(_array.scores.away.quarter_4))) {
            setGameStatus("Win");
            setIsgame(true);
          } else {
            setGameStatus("Loss");
            setIsgame(false);
            claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
          }
        }else if (type == "GameFini") {
          if (Number(points) < (Number(_array.scores.home.quarter_1) + Number(_array.scores.away.quarter_1) + Number(_array.scores.home.quarter_2) + Number(_array.scores.away.quarter_2) + Number(_array.scores.home.quarter_3) + Number(_array.scores.away.quarter_3) + Number(_array.scores.home.quarter_4) + Number(_array.scores.away.quarter_4))) {
            setGameStatus("Win");
            setIsgame(true);
          } else {
            setGameStatus("Loss");
            setIsgame(false);
            claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
          }
        }
      } else if (betType.includes("(Under") == true) {
        let index = betType.lastIndexOf("(Under");
        let points = betType.slice(Number(index) + 7, Number(betType.length) - 1)
        if (type == "1Qtr") {
          if (Number(points) > (Number(_array.scores.home.quarter_1) + Number(_array.scores.away.quarter_1))) {
            setGameStatus("Win");
            setIsgame(true);
          } else {
            setGameStatus("Loss");
            setIsgame(false);
            claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
          }
        }else if (type == "2Qtr") {
          if (Number(points) > (Number(_array.scores.home.quarter_2) + Number(_array.scores.away.quarter_2))) {
            setGameStatus("Win");
            setIsgame(true);
          } else {
            setGameStatus("Loss");
            setIsgame(false);
            claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
          }
        }else if (type == "3Qtr") {
          if (Number(points) > (Number(_array.scores.home.quarter_3) + Number(_array.scores.away.quarter_3))) {
            setGameStatus("Win");
            setIsgame(true);
          } else {
            setGameStatus("Loss");
            setIsgame(false);
            claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
          }
        }else if (type == "4Qtr") {
          if (Number(points) > (Number(_array.scores.home.quarter_4) + Number(_array.scores.away.quarter_4))) {
            setGameStatus("Win");
            setIsgame(true);
          } else {
            setGameStatus("Loss");
            setIsgame(false);
            claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
          }
        }else if (type == "1Half") {
          if (Number(points) > (Number(_array.scores.home.quarter_1) + Number(_array.scores.away.quarter_1) + Number(_array.scores.home.quarter_2) + Number(_array.scores.away.quarter_2))) {
            setGameStatus("Win");
            setIsgame(true);
          } else {
            setGameStatus("Loss");
            setIsgame(false);
            claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
          }
        }else if (type == "2Half") {
          if (Number(points) > (Number(_array.scores.home.quarter_3) + Number(_array.scores.away.quarter_3) + Number(_array.scores.home.quarter_4) + Number(_array.scores.away.quarter_4))) {
            setGameStatus("Win");
            setIsgame(true);
          } else {
            setGameStatus("Loss");
            setIsgame(false);
            claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
          }
        }else if (type == "GameFini") {
          if (Number(points) > (Number(_array.scores.home.quarter_1) + Number(_array.scores.away.quarter_1) + Number(_array.scores.home.quarter_2) + Number(_array.scores.away.quarter_2) + Number(_array.scores.home.quarter_3) + Number(_array.scores.away.quarter_3) + Number(_array.scores.home.quarter_4) + Number(_array.scores.away.quarter_4))) {
            setGameStatus("Win");
            setIsgame(true);
          } else {
            setGameStatus("Loss");
            setIsgame(false);
            claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
          }
        }
      } else if (betType.includes("Spread") == true) {
        if (type == "1Qtr") {
          var homeScore = Number(_array.scores.home.quarter_1);
          var awayScore = Number(_array.scores.away.quarter_1);
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            let index = betType.lastIndexOf("(Home");
            let points = betType.slice(Number(index) + 6, Number(betType.length) - 1);
            if(Math.sign(points) == 1){
              homeScore = homeScore + Math.abs(points);
              if(Number(homeScore) > Number(awayScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }else{
              homeScore = homeScore - Math.abs(points);
              if(Number(homeScore) > Number(awayScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }
          }else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            let index = betType.lastIndexOf("(Away");
            let points = betType.slice(Number(index) + 6, Number(betType.length) - 1);
            if(Math.sign(points) == 1){
              awayScore = awayScore + Math.abs(points);
              if(Number(awayScore) > Number(homeScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }else{
              awayScore = awayScore - Math.abs(points);
              if(Number(awayScore) > Number(homeScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }
          }
        }else if (type == "2Qtr") {
          var homeScore = Number(_array.scores.home.quarter_2);
          var awayScore = Number(_array.scores.away.quarter_2);
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            let index = betType.lastIndexOf("(Home");
            let points = betType.slice(Number(index) + 6, Number(betType.length) - 1);
            if(Math.sign(points) == 1){
              homeScore = homeScore + Math.abs(points);
              if(Number(homeScore) > Number(awayScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }else{
              homeScore = homeScore - Math.abs(points);
              if(Number(homeScore) > Number(awayScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }
          }else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            let index = betType.lastIndexOf("(Away");
            let points = betType.slice(Number(index) + 6, Number(betType.length) - 1);
            if(Math.sign(points) == 1){
              awayScore = awayScore + Math.abs(points);
              if(Number(awayScore) > Number(homeScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }else{
              awayScore = awayScore - Math.abs(points);
              if(Number(awayScore) > Number(homeScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }
          }
        }else if (type == "3Qtr") {
          var homeScore = Number(_array.scores.home.quarter_3);
          var awayScore = Number(_array.scores.away.quarter_3);
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            let index = betType.lastIndexOf("(Home");
            let points = betType.slice(Number(index) + 6, Number(betType.length) - 1);
            if(Math.sign(points) == 1){
              homeScore = homeScore + Math.abs(points);
              if(Number(homeScore) > Number(awayScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }else{
              homeScore = homeScore - Math.abs(points);
              if(Number(homeScore) > Number(awayScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }
          }else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            let index = betType.lastIndexOf("(Away");
            let points = betType.slice(Number(index) + 6, Number(betType.length) - 1);
            if(Math.sign(points) == 1){
              awayScore = awayScore + Math.abs(points);
              if(Number(awayScore) > Number(homeScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }else{
              awayScore = awayScore - Math.abs(points);
              if(Number(awayScore) > Number(homeScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }
          }
        }else if (type == "4Qtr") {
          var homeScore = Number(_array.scores.home.quarter_4);
          var awayScore = Number(_array.scores.away.quarter_4);
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            let index = betType.lastIndexOf("(Home");
            let points = betType.slice(Number(index) + 6, Number(betType.length) - 1);
            if(Math.sign(points) == 1){
              homeScore = homeScore + Math.abs(points);
              if(Number(homeScore) > Number(awayScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }else{
              homeScore = homeScore - Math.abs(points);
              if(Number(homeScore) > Number(awayScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }
          }else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            let index = betType.lastIndexOf("(Away");
            let points = betType.slice(Number(index) + 6, Number(betType.length) - 1);
            if(Math.sign(points) == 1){
              awayScore = awayScore + Math.abs(points);
              if(Number(awayScore) > Number(homeScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }else{
              awayScore = awayScore - Math.abs(points);
              if(Number(awayScore) > Number(homeScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }
          }
        }else if (type == "1Half") {
          var homeScore = Number(_array.scores.home.quarter_1) + Number(_array.scores.home.quarter_2);
          var awayScore = Number(_array.scores.away.quarter_1) + Number(_array.scores.home.quarter_2);
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            let index = betType.lastIndexOf("(Home");
            let points = betType.slice(Number(index) + 6, Number(betType.length) - 1);
            if(Math.sign(points) == 1){
              homeScore = homeScore + Math.abs(points);
              if(Number(homeScore) > Number(awayScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }else{
              homeScore = homeScore - Math.abs(points);
              if(Number(homeScore) > Number(awayScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }
          }else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            let index = betType.lastIndexOf("(Away");
            let points = betType.slice(Number(index) + 6, Number(betType.length) - 1);
            if(Math.sign(points) == 1){
              awayScore = awayScore + Math.abs(points);
              if(Number(awayScore) > Number(homeScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }else{
              awayScore = awayScore - Math.abs(points);
              if(Number(awayScore) > Number(homeScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }
          }
        }else if (type == "2Half") {
          var homeScore = Number(_array.scores.home.quarter_3) + Number(_array.scores.home.quarter_4);
          var awayScore = Number(_array.scores.away.quarter_3) + Number(_array.scores.home.quarter_4);
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            let index = betType.lastIndexOf("(Home");
            let points = betType.slice(Number(index) + 6, Number(betType.length) - 1);
            if(Math.sign(points) == 1){
              homeScore = homeScore + Math.abs(points);
              if(Number(homeScore) > Number(awayScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }else{
              homeScore = homeScore - Math.abs(points);
              if(Number(homeScore) > Number(awayScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }
          }else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            let index = betType.lastIndexOf("(Away");
            let points = betType.slice(Number(index) + 6, Number(betType.length) - 1);
            if(Math.sign(points) == 1){
              awayScore = awayScore + Math.abs(points);
              if(Number(awayScore) > Number(homeScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }else{
              awayScore = awayScore - Math.abs(points);
              if(Number(awayScore) > Number(homeScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }
          }
        }else if (type == "GameFini") {
          var homeScore = Number(_array.scores.home.quarter_1) + Number(_array.scores.home.quarter_2) + Number(_array.scores.home.quarter_3) + Number(_array.scores.home.quarter_4);
          var awayScore = Number(_array.scores.away.quarter_1) + Number(_array.scores.home.quarter_2) + Number(_array.scores.away.quarter_3) + Number(_array.scores.home.quarter_4);
          if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            let index = betType.lastIndexOf("(Home");
            let points = betType.slice(Number(index) + 6, Number(betType.length) - 1);
            if(Math.sign(points) == 1){
              homeScore = homeScore + Math.abs(points);
              if(Number(homeScore) > Number(awayScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }else{
              homeScore = homeScore - Math.abs(points);
              if(Number(homeScore) > Number(awayScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }
          }else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            let index = betType.lastIndexOf("(Away");
            let points = betType.slice(Number(index) + 6, Number(betType.length) - 1);
            if(Math.sign(points) == 1){
              awayScore = awayScore + Math.abs(points);
              if(Number(awayScore) > Number(homeScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }else{
              awayScore = awayScore - Math.abs(points);
              if(Number(awayScore) > Number(homeScore)){
                setGameStatus("Win");
                setIsgame(true);
              }else{
                setGameStatus("Loss");
                setIsgame(false);
                claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
              }
            }
          }
        }
      } else {
        if (type == "1Qtr") {
          if (data.attributes?.betType.search("draw") != -1 || data.attributes?.betType.search("Draw") != -1) {
            if (Number(_array.scores.away.quarter_1) == Number(_array.scores.home.quarter_1)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if (Number(_array.scores.away.quarter_1) < Number(_array.scores.home.quarter_1)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if (Number(_array.scores.away.quarter_1) > Number(_array.scores.home.quarter_1)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "2Qtr") {
          if (data.attributes?.betType.search("draw") != -1 || data.attributes?.betType.search("Draw") != -1) {
            if (Number(_array.scores.away.quarter_2) == Number(_array.scores.home.quarter_2)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if (Number(_array.scores.away.quarter_2) < Number(_array.scores.home.quarter_2)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if (Number(_array.scores.away.quarter_2) > Number(_array.scores.home.quarter_2)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "3Qtr") {
          if (data.attributes?.betType.search("draw") != -1 || data.attributes?.betType.search("Draw") != -1) {
            if (Number(_array.scores.away.quarter_3) == Number(_array.scores.home.quarter_3)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if (Number(_array.scores.away.quarter_3) < Number(_array.scores.home.quarter_3)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if (Number(_array.scores.away.quarter_3) > Number(_array.scores.home.quarter_3)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "4Qtr") {
          if (data.attributes?.betType.search("draw") != -1 || data.attributes?.betType.search("Draw") != -1) {
            if (Number(_array.scores.away.quarter_4) == Number(_array.scores.home.quarter_4)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if (Number(_array.scores.away.quarter_4) < Number(_array.scores.home.quarter_4)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if (Number(_array.scores.away.quarter_4) > Number(_array.scores.home.quarter_4)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "1Half") {
          if (data.attributes?.betType.search("draw") != -1 || data.attributes?.betType.search("Draw") != -1) {
            if ((Number(_array.scores.away.quarter_1) + Number(_array.scores.away.quarter_2)) == (Number(_array.scores.home.quarter_1) + Number(_array.scores.home.quarter_2))) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if ((Number(_array.scores.away.quarter_1) + Number(_array.scores.away.quarter_2)) < (Number(_array.scores.home.quarter_1) + Number(_array.scores.home.quarter_2))) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if ((Number(_array.scores.away.quarter_1) + Number(_array.scores.away.quarter_2)) > (Number(_array.scores.home.quarter_1) + Number(_array.scores.home.quarter_2))) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "2Half") {
          if (data.attributes?.betType.search("draw") != -1 || data.attributes?.betType.search("Draw") != -1) {
            if ((Number(_array.scores.away.quarter_3) + Number(_array.scores.away.quarter_4)) == (Number(_array.scores.home.quarter_3) + Number(_array.scores.home.quarter_4))) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if ((Number(_array.scores.away.quarter_3) + Number(_array.scores.away.quarter_4)) < (Number(_array.scores.home.quarter_3) + Number(_array.scores.home.quarter_4))) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if ((Number(_array.scores.away.quarter_3) + Number(_array.scores.away.quarter_4)) > (Number(_array.scores.home.quarter_3) + Number(_array.scores.home.quarter_4))) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        } else if (type == "GameFini") {
          if (data.attributes?.betType.search("draw") != -1 || data.attributes?.betType.search("Draw") != -1) {
            if (Number(_array.scores.away.total) == Number(_array.scores.home.total)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("home") != -1 || data.attributes?.betType.search("Home") != -1) {
            if (Number(_array.scores.away.total) < Number(_array.scores.home.total)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          } else if (data.attributes?.betType.search("away") != -1 || data.attributes?.betType.search("Away") != -1) {
            if (Number(_array.scores.away.total) > Number(_array.scores.home.total)) {
              setGameStatus("Win");
              setIsgame(true);
            } else {
              setGameStatus("Loss");
              setIsgame(false);
              claim(data.attributes?.odds,data.attributes?.winAmount,data.id,data.attributes)
            }
          }
        }
      }
    } else {
      setIsgame(true);
    }
  }

  const claim = async (odds, amount, objectId, details) => {
    setLoader(true);
    console.log("objectId: ", objectId);
    console.log("gameStatus: ", gameStatus)
    if (gameStatus == "Win") {
      const res = await claimBet(Number(odds) + 2, amount); // amount);
      if (res) {
        const data = {
          odds: details.odds,
          userAddress: localStorage.getItem("userAddress").toString(),
          betType: details.betType,
          gameId: details.gameId,
          winAmount: details.winAmount,
          transactionHash: res.transactionHash,
          amount: details.amount,
          gameData: details.gameData,
          status: "Win",
          betsId: objectId
        };

        save(data, {
          onSuccess: (monster) => {
            deleteData(objectId);
            snackBar(
              "Claim bet successfully. With transaction has " +
              res.transactionHash,
              "success"
            );
            setLoader(false);
            setTimeout(() => {
              getActiveBets();
              getSettleBets();
            }, 1000);
            // deleteData(objectId);
            // setTimeout(() => {
            //   window.location.reload();
            // }, 2000);
          },
          onError: (error) => {
            snackBar(
              "Failed to create new object, with error code: " + error.message,
              "danger"
            );
          },
        });
      } else {
        snackBar("Something went wrong", "danger");
        setLoader(false);
      }
    } else {

      const query = new Moralis.Query("SettleBets");
      query.equalTo("betsId", objectId);
      const results = await query.find();
      if (results.length == 0) {
        console.log("Loss Condition");
        const data1 = {
          odds: details.odds,
          userAddress: localStorage.getItem("userAddress").toString(),
          betType: details.betType,
          gameId: details.gameId,
          winAmount: Number("00"),
          transactionHash: "",
          amount: details.amount,
          gameData: details.gameData,
          status: "Loss",
          betsId: objectId
        };

        save(data1, {
          onSuccess: (monster) => {
            deleteData(objectId);
            getActiveBets();
            getSettleBets();
            setLoader(false);
          },
          onError: (error) => {
          },
        });
      }
    }
  };

  const getSingleMatcheDetails = async (matchId) => {
    try {
      const endPoint = "getSingleGameDetails";
      const response = await getSingleGame(endPoint, matchId);

      if (response) {
        array = response.data.Data[0];
        var gameD = data.attributes?.gameData;
        console.log(gameD, "response");
        console.log("gameD?.betType : " + data.attributes?.betType);

        if (
          (data.attributes?.betType.search("1st Quarter") != -1 || data.attributes?.betType.search("1 Quarter") != -1) &&
          array.status?.long != "Quarter 1" &&
          array.status?.long != "Not Started"
        ) {
          setStatus(true);
          getWinLoss(true, array, "1Qtr", data.attributes?.betType)
          console.log("Status1 : " + status);
        } else if (
          (data.attributes?.betType.search("2nd Quarter") != -1 || data.attributes?.betType.search("2 Quarter") != -1) &&
          array.status?.long != "Quarter 2" &&
          array.status?.long != "Quarter 1" &&
          array.status?.long != "Not Started"
        ) {
          setStatus(true);
          getWinLoss(true, array, "2Qtr", data.attributes?.betType)
          console.log("Status2 : " + status);
        } else if (
          (data.attributes?.betType.search("1st Half") != -1 || data.attributes?.betType.search("1 Half") != -1) &&
          array.status?.long != "Quarter 2" &&
          array.status?.long != "Quarter 1" &&
          array.status?.long != "Not Started"
        ) {
          setStatus(true);
          getWinLoss(true, array, "1Half", data.attributes?.betType)
          console.log("Status3 : " + status, data.attributes?.betType);
        } else if (
          (data.attributes?.betType.search("3rd Quarter") != -1 || data.attributes?.betType.search("3 Quarter") != -1) &&
          array.status?.long != "Quarter 3" &&
          array.status?.long != "Quarter 2" &&
          array.status?.long != "Quarter 1" &&
          array.status?.long != "Not Started"
        ) {
          setStatus(true);
          getWinLoss(true, array, "3Qtr", data.attributes?.betType)
          console.log("Status4 : " + status);
        } else if (
          (data.attributes?.betType.search("4th Quarter") != -1 || data.attributes?.betType.search("4 Quarter") != -1) &&
          array.status?.long != "Quarter 4" &&
          array.status?.long != "Quarter 3" &&
          array.status?.long != "Quarter 2" &&
          array.status?.long != "Quarter 1" &&
          array.status?.long != "Not Started"
        ) {
          setStatus(true);
          getWinLoss(true, array, "4Qtr", data.attributes?.betType)
          console.log("Status5 : " + status);
        } else if (
          (data.attributes?.betType.search("2nd Half") != -1 || data.attributes?.betType.search("2 Half") != -1) &&
          array.status?.long != "Quarter 4" &&
          array.status?.long != "Quarter 3" &&
          array.status?.long != "Quarter 2" &&
          array.status?.long != "Quarter 1" &&
          array.status?.long != "Not Started"
        ) {
          setStatus(true);
          getWinLoss(true, array, "2Half", data.attributes?.betType)
          console.log("Status6 : " + status);
        } else if (array.status?.long == "Game Finished") {
          setStatus(true);
          getWinLoss(true, array, "GameFini", data.attributes?.betType)
          console.log("Status7 : " + status);
        } else {
          setStatus(false);
          getWinLoss(false, array, "NotFound", "NotFound")
          console.log("Status7 : " + status);
        }
      } else {
        setStatus(false);
        console.log("Status8 : " + status);
      }
    } catch (error) {
      console.log("error" + error);
      setStatus(false);
      console.log("Status 9: " + status);
    }
  };

  const getTeamName = (data, bettype) => {
    console.log("getTeamNamedata : " + data);
    if (bettype?.search("Home") > 0 || bettype?.search("home") > 0) {
      return data?.home?.name
    } else if (bettype?.search("Away") > 0 || bettype?.search("away") > 0) {
      return data?.away?.name;
    } else {
      return data?.home?.name + " - " + data?.away?.name;
    }
  }

  const getCashOutBtn = () => {
    return (
      <>
        <Box className={`${classes.footerBetWin} flexjustifycenter`} style={{ background: gameStatus == "Loss" && "red" }}>
          {loader ? (
            <Button
              variant="contained"
              size="large"
              disabled
              fullWidth
              className={`${classes.cashoutButton}`}
              style={{ background: gameStatus == "Loss" && "red" }}
            >
              <CircularProgress color="secondary" />
            </Button>
          ) : (
            <Button
              variant="contained"
              size="large"
              style={{ background: gameStatus == "Loss" && "red" }}
              onClick={() =>
                claim(
                  data.attributes?.odds,
                  data.attributes?.winAmount,
                  data.id,
                  data.attributes
                )
              }
              fullWidth
              className={`${classes.cashoutButton}`}
            >
              {gameStatus == "Loss" ? <>Settle Bet</> : <>Cashout ${data.attributes?.winAmount}</>}
            </Button>
          )}
        </Box>
      </>
    );
  };

  return (
    <>
      {snackBarContent && (
        <SnackbarService msg={snackBarMsg} status={snackBarStatus} />
      )}
      {isgame &&
        <Paper className={classes.mainBox} elevation={2}>
          <Box className={`${classes.headerBetWin} flexjustifycenter`}>
            <Typography variant="body2">
              {dateFormat(data.attributes?.createdAt, "hh:MM TT dd/mm/yyyy")}
            </Typography>
          </Box>
          <Box className={classes.secondMainBox}>
            <Box pb={1}>
              <Box my={1} className={`${classes.subtitle} flexjustify`}>
                <IoBasketballSharp />
                <Typography variant="body2">
                  {getTeamName(data.attributes?.gameData?.teams, data.attributes?.betType)}
                </Typography>
              </Box>
              <Box my={1} className={`${classes.subtitle} flexjustify`}>
                <Typography variant="body2">
                  {data.attributes?.betType}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" style={{ color: "#39AED0" }}>
                  Correct Score
                </Typography>
              </Box>
              <Box className={`${classes.subtitle} justifyBetween`}>
                <Typography variant="h6">
                  <BsCheck2 />
                  {data.attributes?.odds}
                </Typography>
                <Typography variant="h6" color="primary">
                  {" "}
                  {data.attributes?.odds}{" "}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6">2-3</Typography>
              </Box>
            </Box>
            {/* <Box className={classes.loweroponent}>
                          <Box my={1} className={`${classes.subtitle} flexjustify`}>
                              <IoBasketballSharp />
                              <Typography variant="body2">
                                  Fritz, Taylor - Nadal, Rafael
                              </Typography>
                          </Box>
                          <Box>
                              <Typography variant="body1" style={{ color: "#39AED0" }}>
                                  Correct Score
                              </Typography>
                          </Box>
                          <Box
                              className={`${classes.subtitle} justifyBetween`}>
                              <Typography variant="h6"><BsCheck2 />2:3</Typography>
                              <Typography variant="h6" color="primary"> 2.05 </Typography>
                          </Box>
                          <Box>
                              <Typography variant="h6" >2-3</Typography>
                          </Box>
                      </Box> */}
            <hr style={{ border: "1px solid rgba(71, 71, 71, 0.3)" }} />
            <Box mt={1}>
              <Box className={classes.lowerbetbox}>
                <Box className="justifyBetween">
                  <Typography variant="body1" className="blue">
                    Total Odds
                  </Typography>
                  <Typography variant="h6">{data.attributes?.odds}</Typography>
                </Box>
                <Box className="justifyBetween">
                  <Typography variant="body1" className="blue">
                    Total Stake
                  </Typography>
                  <Typography variant="body1" className="icontext">
                    {" "}
                    $ {data.attributes?.amount} {/*<SiBinance />*/}{" "}
                  </Typography>
                </Box>
                <Box className="justifyBetween">
                  <Typography variant="body1" className="blue">
                    Payout
                  </Typography>
                  <Typography variant="body1" className="icontext green">
                    {" "}
                    {gameStatus == "Loss" ? <>$ 00</> : <>$ {data.attributes?.winAmount}</>}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {status == true ? getCashOutBtn() : ""}
        </Paper>
      }
    </>
  );
}

export default ActiveBetsCard;
